<template>
  <v-card class="py-3" flat outlined>
    <v-card-title v-if="label">
      {{ label }}
    </v-card-title>
    <v-layout justify-center v-if="value">
      <v-icon size="100"> description </v-icon>
    </v-layout>
    <v-row justify="center" class="my-3">
      <slot name="additionalInfo" />
    </v-row>
    <v-layout justify-center align-center>
      <v-btn link @click="downloadFile" v-if="value"> Abrir arquivo </v-btn>
      <v-btn
        :loading="uploading"
        color="success"
        class="ml-2"
        @click="$refs.inputUpload.click()"
      >
        {{ buttonText }}
      </v-btn>
      <input
        v-show="false"
        ref="inputUpload"
        type="file"
        @change="load"
        accept="application/pdf"
      />
    </v-layout>
  </v-card>
</template>

<script>
import { getConsentTermPreSignedUrl, uploadToS3 } from "@/services/aws-service";

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    file: "",
    uploading: false,
  }),
  watch: {
    value(value) {},
  },
  computed: {
    buttonText() {
      return (this.value ? "Alterar" : "Novo") + " Arquivo";
    },
  },
  created() {},
  methods: {
    load() {
      if (this.$refs.inputUpload.files) {
        this.file = this.$refs.inputUpload.files[0];
      //  if (!this.validate()) return;
        const fr = new FileReader();
        fr.readAsDataURL(this.file);
        fr.addEventListener("load", () => {
          this.upload(this.file.type);
        });
      }
    },
    async generateUrl(contentType) {
      const {
        data: { resourceUrl, key, url, extension },
      } = await getConsentTermPreSignedUrl(contentType);
      return {
        resourceUrl,
        key,
        contentType,
        url,
        extension,
      };
    },
    async upload(fileType) {
      this.uploading = true;
      try {
        const { resourceUrl, key, contentType, url } = await this.generateUrl(
          fileType
        );
        await uploadToS3({ image: this.file, key, url, contentType });
        this.$emit("input", resourceUrl);
      } catch (error) {
        this.$toasted.global.defaultError();
      } finally {
        this.uploading = false;
      }
    },
    validate() {
      const fileSizeInKb = this.file.size / 1000;
      const maxSizeInKb = 2000;
      if (fileSizeInKb > maxSizeInKb) {
        this.$toasted.global.error({
          message: "Tamanho máximo de arquivo: 2mb",
        });
        return false;
      }

      return true;
    },
    downloadFile() {
      window.open(this.value);
    },
  },
};
</script>

<style></style>
